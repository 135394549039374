// Import helper function
import { ipAddress as ipAddressValidator } from 'vuelidate/lib/validators'

/**
 * Export the helper validator function
 *
 * @param {String} value
 * @returns {Boolean}
 */
export default function (value) {
  // Regular expression to match valid subnet values (e.g., "192.168.0.0/24")
  const subnetRegex = /^(?:\d{1,3}\.){3}\d{1,3}\/\d{1,2}$/

  // If the value didn't match, respond with false
  if (!subnetRegex.test(value)) {
    return false
  }

  // Split the value into IP address and subnet mask parts
  const [ipAddress, subnetMask] = value.split('/')

  // Validate IP address using vuelidate's built-in IP validator
  if (!ipAddressValidator(ipAddress)) {
    // If the ipAddress value is invalid, return false
    return false
  }

  // Validate subnet mask value
  const parsedSubnetMask = parseInt(subnetMask, 10)

  if (isNaN(parsedSubnetMask) || parsedSubnetMask < 0 || parsedSubnetMask > 32) {
    return false
  }

  // Fallback to true
  return true
}
